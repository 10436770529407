<template>
  <Layout>
    <div class="page-description">
      管理游戏标签。你可以增加，删除，修改和查找标签列表里的标签。
    </div>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        @submit.native.prevent
      >
        <el-form-item prop="keyWord">
          <el-input
            v-model="formData.keyWord"
            clearable
            placeholder="标签名称"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="prrmary"
          @click="handleCreateTag"
        >
          新增标签
        </el-button>
      </template>
    </BHeader>

    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="标签ID"
        prop="tagId"
        width="100px"
      />
      <el-table-column
        label="标签名称"
        prop="webTagNameZh"
        min-width="120px"
      />
      <el-table-column
        label="标签分类"
        prop="tagGroupName"
        width="150px"
      />
      <el-table-column
        label="描述类别"
        prop="secondTagGroupName"
      />
      <el-table-column
        label="关联游戏数量"
        prop="gameCount"
        width="110px"
      />
      <el-table-column
        label="操作"
        width="140px"
      >
        <template #default="{row}">
          <BTextButton
            title="编辑"
            icon="fa fa-pencil"
            @click="handleEditItem(row)"
          />
          <BTextButton
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />

    <el-dialog
      :title="dialogTile"
      :visible.sync="showDialog"
      width="600px"
      @close="handleDialogClose"
    >
      <el-form
        ref="subForm"
        v-loading="showDialogLoading"
        :model="subForm"
        :rules="subFormRules"
        label-width="100px"
      >
        <div class="header">
          标签名称
        </div>
        <el-form-item
          label="中文"
          prop="zhTagName"
        >
          <el-input
            v-model="subForm.zhTagName"
            maxlength="30"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="英文"
          prop="enTagName"
        >
          <el-input
            v-model="subForm.enTagName"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="泰语"
          prop="thTagName"
        >
          <el-input
            v-model="subForm.thTagName"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>

        <div class="header">
          标签详细信息
        </div>
        <el-form-item
          label="标签类别"
          prop="parentGroupId"
        >
          <el-select
            v-model="subForm.parentGroupId"
            placeholder="请选择"
            @change="$set(subForm, 'secondGroupId', '')"
          >
            <el-option
              v-for="item in tagTypeList"
              :key="item.parent.id"
              :label="item.parent.groupName"
              :value="item.parent.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="描述类别"
          prop="secondGroupId"
        >
          <el-select
            v-model="subForm.secondGroupId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in descTypeList"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleConfirm"
        >确 定</el-button>
      </span>
    </el-dialog>
  </Layout>
</template>

<script>

import {
  queryAllGameTagList,
  queryGameTagGroupList,
  deleteGameTag,
  queryGameTagdetail,
  // tagNameValidateCheck,
  createNewTag,
  updateGameTag
} from '@/api/gameList.js'
export default {
  data () {
    // const repeatNameValidator = (rule, value, callback) => {
    //   tagNameValidateCheck({
    //     tagId: this.subForm.tagId || '',
    //     keyword: value
    //   }).then(res => {
    //     if (!res.data.existed) {
    //       callback()
    //     } else {
    //       callback(new Error('标签名已存在'))
    //     }
    //   })
    // }
    return {
      tagTypeList: [],
      descTypeMap: {},
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      showDialog: false,
      dialogTile: '',
      subForm: {},
      subFormRules: {
        zhTagName: [
          { required: true, message: '中文名称必填' }
          // { validator: repeatNameValidator }
        ],
        enTagName: [
          { required: true, message: '英文名称必填' }
        ],
        thTagName: [
          { required: true, message: '泰语名称必填' }
        ],
        parentGroupId: [
          { required: true, message: '标签类别必选' }
        ],
        secondGroupId: [
          { required: true, message: '描述类别必选' }
        ]
      },
      showDialogLoading: false,
      updateLoading: false
    }
  },
  computed: {
    descTypeList () {
      return this.descTypeMap[this.subForm.parentGroupId] || []
    }
  },
  created () {
    this.handleSearch()
    this.queryGameTagGroupList()
  },
  methods: {
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      this.listInfo.loading = true
      queryAllGameTagList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    queryGameTagGroupList () {
      queryGameTagGroupList({})
        .then(res => {
          if (res.code === 200) {
            this.tagTypeList = res.data.list || []
            this.descTypeMap = this.tagTypeList.reduce((result, item) => {
              result[item.parent.id] = item.childList || []
              return result
            }, {})
          }
        })
    },
    handleCreateTag () {
      this.dialogTile = '新增游戏标签'
      this.showDialog = true
    },
    handleEditItem (row) {
      this.dialogTile = '编辑游戏标签'
      this.showDialog = true
      this.showDialogLoading = true
      queryGameTagdetail({
        tagId: row.tagId
      })
        .then(res => {
          if (res.code === 200) {
            this.subForm = res.data
          }
        })
        .catch(() => {
          this.showDialog = false
        })
        .finally(() => {
          this.showDialogLoading = false
        })
    },
    handleDeleteItem (row) {
      this.$confirm('此操作将删除游戏标签, 是否继续', '提示')
        .then(() => {
          this.$set(row, 'deleteLoading', true)
          deleteGameTag({
            tagId: row.tagId
          }).then(res => {
            if (res.code === 200) {
              this.queryDataList()
            }
          }).finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
        })
    },
    handleDialogClose () {
      this.subForm = {}
      this.$refs.subForm.resetFields()
    },
    handleConfirm () {
      this.$refs.subForm.validate(valid => {
        if (valid) {
          this.updateLoading = true
          if (this.dialogTile === '新增游戏标签') {
            createNewTag(this.subForm)
              .then(res => {
                if (res.code === 200) {
                  this.$message.success('新建标签成功')
                  this.showDialog = false
                  this.queryDataList()
                }
              })
              .finally(() => {
                this.updateLoading = false
              })
          } else {
            updateGameTag(this.subForm)
              .then(res => {
                if (res.code === 200) {
                  this.$message.success('更新标签成功')
                  this.showDialog = false
                  this.queryDataList()
                }
              })
              .finally(() => {
                this.updateLoading = false
              })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .page-description {
    font-size: 14px;
    color: #aaa;
    margin: 30px 0;
  }
  .header {
    font-size: 16px;
    padding: 0 0 20px 20px;
  }
</style>
